import React, { useEffect, useMemo } from 'react'

import { useLogger } from 'hooks/useLogger'
import { TalosDataResponse } from 'types/talos-data-response'

// TBD: instead use response processor singleton that updates the store

const makeProcessResponse = <DataType>(
  setLatestResponse: React.Dispatch<
    React.SetStateAction<TalosDataResponse<DataType>>
  >,
  reqId: number,
  logger,
) => {
  return reqId == null
    ? null
    : (evt: MessageEvent): void => {
        try {
          const dataResponse = JSON.parse(
            evt.data,
          ) as TalosDataResponse<DataType>

          if (dataResponse.reqid !== reqId) {
            return
          }

          setLatestResponse(dataResponse)
        } catch (error) {
          logger.error(error)
        }
      }
}

/**
 * hooks/useSubscription/useResponseProcessor
 * @deprecated use singleton store updater instead
 */
export const useResponseProcessor = <DataType>(
  socket: WebSocket,
  setLatestResponse: React.Dispatch<
    React.SetStateAction<TalosDataResponse<DataType>>
  >,
  reqId: React.RefObject<number>,
): void => {
  const logger = useLogger()

  const processResponse = useMemo(
    () => makeProcessResponse(setLatestResponse, reqId.current, logger),
    [setLatestResponse, reqId, logger],
  )

  useEffect(() => {
    if (socket && processResponse) {
      socket.addEventListener('message', processResponse)

      return () => {
        socket.removeEventListener('message', processResponse)
      }
    }
  }, [socket, processResponse])
}
