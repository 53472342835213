import Decimal from 'decimal.js-light'
import { chunk, isNil, range } from 'lodash'

const PRECISION = 4
const DIGIT_GROUPING_LEN = 3
const DIGIT_GROUPING_SEPARATOR = ','

export type TalosDecimal = string
export type DecimalParts = [string, string]

const getNZeros = (n) =>
  range(n)
    .map(() => '0')
    .join('')

export const padRightWithZeros = (x: TalosDecimal, n: number): TalosDecimal => {
  const numMissingZeros = n - x.length

  return 0 >= numMissingZeros ? x : `${x}${getNZeros(numMissingZeros)}`
}

export const roundDecimalPartToHundredths = (
  decimalPart: TalosDecimal,
): TalosDecimal => {
  const decimalPartToThousandths = padRightWithZeros(
    decimalPart.substring(0, 3),
    3,
  )

  const nx = maybeNumber(decimalPartToThousandths)

  return padRightWithZeros(Math.round(nx / 10).toString(), 2)
}

export const addDigitGrouping = (x: TalosDecimal): string => {
  if (!x) return null

  const snum: string = 'number' === typeof x ? (x as number).toString() : x

  const [integralPart, fractionalPart] = snum.split('.')

  const charList = integralPart.trim().split('')
  let maybeMinusSign = ''
  if ('-' === charList?.[0]) {
    charList.shift()
    maybeMinusSign = '-'
  }

  const reverseCharList = charList.reverse()
  const reverseDigitGrouping = chunk(reverseCharList, DIGIT_GROUPING_LEN)
  const digitGrouping = reverseDigitGrouping
    .map((dg) => dg.reverse().join(''))
    .reverse()
    .join(DIGIT_GROUPING_SEPARATOR)

  const separatorAndFractionalPart = fractionalPart ? `.${fractionalPart}` : ''

  return `${maybeMinusSign}${digitGrouping}${separatorAndFractionalPart}`
}

export const maybeNumber = (x: TalosDecimal): number | null => {
  if (!x && 0 !== (x as unknown as number)) return null
  if (true === (x as unknown as boolean)) return null

  const xNum = Number(x)

  return isNaN(xNum) ? null : xNum
}

export const formatDecimal = (x: TalosDecimal): string => {
  const xNum = maybeNumber(x)

  return isNil(xNum)
    ? ''
    : xNum <= 0
    ? xNum.toString()
    : addDigitGrouping(xNum.toFixed(2))
}

export const setPricePrecision = (x: TalosDecimal): TalosDecimal => {
  const nx = maybeNumber(x)
  if (isNil(nx)) return null

  const snumAtPrecision = nx.toPrecision(PRECISION) // '1230', '1.230e+8', etc

  const [base, exp] = snumAtPrecision.split('e')

  const nxBase = new Decimal(maybeNumber(base))
  const nxExp = maybeNumber(exp) ?? 0

  const factor = Math.pow(10, nxExp)

  const nonPaddedResult = nxBase.mul(factor).toFixed()

  const [maybeMinus, absNonPad] =
    '-' === nonPaddedResult[0]
      ? ['-', nonPaddedResult.substring(1)]
      : ['', nonPaddedResult]

  if ('0' === absNonPad) {
    // or '-0'
    return `0.${getNZeros(PRECISION)}`
  }

  if ('0.' === absNonPad.substring(0, 2)) {
    const digits = absNonPad.substring(2)
    const leftTrimmedDigits = digits.replace(/^0+/, '')

    const numMissingDigits = PRECISION - leftTrimmedDigits.length

    return `${maybeMinus}0.${digits}${getNZeros(numMissingDigits)}`
  }

  const [integralPart, fractionalPart] = absNonPad.split('.')
  const maybeFrac = fractionalPart ?? ''
  const numMissingDigits = PRECISION - (integralPart.length + maybeFrac.length)

  if (0 < numMissingDigits) {
    return `${maybeMinus}${integralPart}.${maybeFrac}${getNZeros(
      numMissingDigits,
    )}`
  }

  return nonPaddedResult
}

export const splitDecimal = (x: TalosDecimal): DecimalParts => {
  if (!x) return null

  const [integer, decimal] = x.split('.')

  return [
    integer.length > 0 && '-0' !== integer ? addDigitGrouping(integer) : '0',
    decimal ? decimal : '00',
  ]
}

/**
 * decimalComparator - Return 1 of valueA is greater than valueB and 0 if their are equal
 */
export const decimalComparator = (
  valueA: TalosDecimal,
  valueB: TalosDecimal,
) => {
  const NumberA = maybeNumber(valueA)
  const NumberB = maybeNumber(valueB)

  if (isNil(valueA)) return -1
  if (isNil(valueB)) return 1

  if (NumberA === NumberB) return 0
  return NumberA > NumberB ? 1 : -1
}

export const compress = (num: string) => {
  const maybe = maybeNumber(num)
  if (!maybe) return ''

  if (maybe >= Math.pow(10, 6)) {
    return (maybe / Math.pow(10, 6)).toPrecision(3).concat('M')
  } else if (maybe >= Math.pow(10, 3)) {
    return (maybe / Math.pow(10, 3)).toPrecision(3).concat('T')
  } else return formatDecimal(num)
}

export const toPrecision = (x: TalosDecimal) => {
  if (!x || x.length === 0) return ''

  const parsed = maybeNumber(x)
  if (parsed) {
    if (parsed < 1) return parsed.toPrecision(4)

    return addDigitGrouping(parsed.toPrecision(5))
  }

  return ''
}
