import React from 'react'
import { useUpdateEffect } from 'react-use'

import { useIsConnected } from 'hooks/useIsConnected'
import { useNonidleCount } from 'hooks/useNonidleCount'
import { useReconnect } from 'hooks/useReconnect'

const IDLE_THRESHOLD_MS = 5000

const ConnectionMonitor: React.FC = () => {
  const reconnect = useReconnect()
  const connected = useIsConnected()
  const nonIdleCount = useNonidleCount(IDLE_THRESHOLD_MS)

  useUpdateEffect(() => {
    if (!connected) {
      reconnect()
    }
  }, [connected, nonIdleCount])

  return null
}

export default ConnectionMonitor
