import { useContext, useMemo } from 'react'

import { FeatureFlagsContext } from 'context'

export const useFeatureFlags = (
  features: string[],
): Record<string, boolean> => {
  const { isFeatureEnabled } = useContext(FeatureFlagsContext)

  return useMemo(
    () =>
      Object.fromEntries(
        features.map((featureName) => [
          featureName,
          isFeatureEnabled(featureName),
        ]),
      ),
    [features, isFeatureEnabled],
  )
}
