import { StreamParameters } from 'types/api/stream-parameters'

export const createInitialSubscriptionMessage = (
  reqid: number,
  stream: StreamParameters,
) => {
  return JSON.stringify({
    type: 'subscribe',
    ts: new Date().toISOString(),
    reqid,
    streams: [stream],
  })
}

export const createSubscription = (
  socket: WebSocket,
  reqid: number,
  stream: StreamParameters,
) => {
  const message = createInitialSubscriptionMessage(reqid, stream)
  socket.send(message)
}

export const createCancelSubscriptionMessage = (reqid: number) => {
  return JSON.stringify({
    reqid,
    type: 'cancel',
  })
}
