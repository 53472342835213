import 'twin.macro'

import BritishPoundIcon from 'assets/icons/british-pound.svg'
import OneInchIcon from 'assets/icons/crypto/1inch.svg'
import AvalancheIcon from 'assets/icons/crypto/avalanche.svg'
import BitcoinIcon from 'assets/icons/crypto/bitcoin.svg'
import CardanoCoin from 'assets/icons/crypto/cardano.svg'
import CronosIcon from 'assets/icons/crypto/cronos.svg'
import DogeIcon from 'assets/icons/crypto/dogecoin.svg'
import EthereumIcon from 'assets/icons/crypto/ethereum.svg'
import FantomIcon from 'assets/icons/crypto/fantom.svg'
import HuobiIcon from 'assets/icons/crypto/huobi.svg'
import LitecoinIcon from 'assets/icons/crypto/litecoin.svg'
import PancakeSwapIcon from 'assets/icons/crypto/pancake-swap.svg'
import PolkadotIcon from 'assets/icons/crypto/polkadot.svg'
import PolygonIcon from 'assets/icons/crypto/polygon.svg'
import RippleIcon from 'assets/icons/crypto/ripple.svg'
import ShibaIcon from 'assets/icons/crypto/shiba.svg'
import SolanaIcon from 'assets/icons/crypto/solana.svg'
import TetherIcon from 'assets/icons/crypto/tether.svg'
import TronIcon from 'assets/icons/crypto/tron.svg'
import UniswapIcon from 'assets/icons/crypto/uniswap.svg'
import USDCIcon from 'assets/icons/crypto/usdc.svg'
import YearnIcon from 'assets/icons/crypto/yearn.svg'
import DollarIcon from 'assets/icons/dollar.svg'
import EuroIcon from 'assets/icons/euro.svg'
import SwissFrancIcon from 'assets/icons/swiss-franc.svg'

export const CurrencyIconMap: Record<string, () => JSX.Element> = {
  BTC: () => <BitcoinIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  ETH: () => <EthereumIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  USD: () => <DollarIcon tw="fill-light-500 h-[18px] w-[16px]" />,
  EUR: () => <EuroIcon tw="fill-light-500 h-[18px] w-[18px]" />,
  DOGE: () => <DogeIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  USDC: () => <USDCIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  TRX: () => <TronIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  SHIB: () => <ShibaIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  XRP: () => <RippleIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  LTC: () => <LitecoinIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  ADA: () => <CardanoCoin tw="fill-light-200 h-[18px] w-[18px]" />,
  CRO: () => <CronosIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  USDT: () => <TetherIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  SOL: () => <SolanaIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  FTM: () => <FantomIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  MATIC: () => <PolygonIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  HT: () => <HuobiIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  AVAX: () => <AvalancheIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  '1INCH': () => <OneInchIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  YFI: () => <YearnIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  UNI: () => <UniswapIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  CAKE: () => <PancakeSwapIcon tw="fill-light-200 h-[18px] w-[18px]" />,
  CHF: () => <SwissFrancIcon tw="fill-light-500 h-[18px] w-[18px]" />,
  GBP: () => <BritishPoundIcon tw="fill-light-500 h-[18px] w-[18px]" />,
  DOT: () => <PolkadotIcon tw="fill-light-200 h-[18px] w-[18px]" />,
}
