/* eslint-disable no-console */

import { useFeatureFlags } from './useFeatureFlags'

/**
 * useLogger.ts
 *
 * Provide a logger which, if enabled, records the parameters at the specified log level,
 * and does nothing otherwise.
 *
 * Not to be confused with the excellent `useLogger` provided by `react-use`, that
 * serves a different purpose.
 * c.f. https://streamich.github.io/react-use/?path=/story/lifecycle-uselogger--docs
 */

const LOGGING_FEATURE_FLAG = 'logging'

type Logger = {
  trace: typeof console.trace
  debug: typeof console.debug
  info: typeof console.log
  warn: typeof console.warn
  error: typeof console.error
}

const ENABLED_LOGGER = {
  trace: console.trace,
  debug: console.debug,
  info: console.log,
  warn: console.warn,
  error: console.error,
}

const NO_OP = () => {}

const DISABLED_LOGGER = {
  trace: NO_OP,
  debug: NO_OP,
  info: NO_OP,
  warn: NO_OP,
  error: NO_OP,
}

export const useLogger = (): Logger => {
  const { [LOGGING_FEATURE_FLAG]: isEnabled } = useFeatureFlags([
    LOGGING_FEATURE_FLAG,
  ])

  return isEnabled ? ENABLED_LOGGER : DISABLED_LOGGER
}
