import { useCallback } from 'react'

export const useRedirectToLogin = (loginUrl: string) => {
  const redirectToLogin = useCallback(() => {
    try {
      if (process.env.NODE_ENV !== 'development') {
        window.location.assign(loginUrl)
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }, [loginUrl])

  return redirectToLogin
}
