import { useCounter, useIdle, useUpdateEffect } from 'react-use'

export const useNonidleCount = (ms?: number): number => {
  const [nonidleCount, { inc }] = useCounter()
  const idle = useIdle(ms)

  useUpdateEffect(() => {
    if (!idle) {
      inc()
    }
  }, [idle, inc])

  return nonidleCount
}
