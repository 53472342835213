import { createContext, useState } from 'react'

import { IsFeatureEnabled, isFeatureEnabled } from 'lib/feature-flags'

interface FeatureFlagsContextType {
  isFeatureEnabled: IsFeatureEnabled
}

const defaultContextValue = {
  isFeatureEnabled: () => false,
}

export const FeatureFlagsContext =
  createContext<FeatureFlagsContextType>(defaultContextValue)

export const FeatureFlagsContextProvider: React.FC<{
  children?: JSX.Element | JSX.Element[]
  isFeatureEnabledTestOverride?: IsFeatureEnabled
}> = ({ children, isFeatureEnabledTestOverride }) => {
  // TBD: on mount request feature flags, suspend rendering children until obtained
  const [featureFlagsContext] = useState({
    isFeatureEnabled: isFeatureEnabledTestOverride ?? isFeatureEnabled,
  })

  return (
    <FeatureFlagsContext.Provider value={featureFlagsContext}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
