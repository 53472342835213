import { subMonths } from 'date-fns'
import { isNil } from 'lodash'

import { ClipIntervalUnits } from 'types/clip-interval-units'
import { Ladder, Rung } from 'types/ladder'

import { CurrencyIconMap } from './currency-icon-map'
import { maybeNumber } from './decimal-util'

/**
 * calculateSpread - Return the *spread* between the specified `ask` and `bid` prices.
 */
export const calculateSpread = (ask: number, bid: number): number =>
  ((ask - bid) / ((ask + bid) / 2)) * 10000

/**
 * createReqId
 * TBD: take the next number in sequence from a counter living with the socket
 * @deprecated See SocketContext `getNextReqId`
 */
export const createReqId = () => Math.floor(Math.random() * Math.pow(10, 4))

/**
 * findRung - Return the *Rung* whose size is the largest in the specified `Ladder`
 * having size less than or equal to the specified `amount`.
 */
export const findRung = (amount: string, ladder: Ladder): Rung => {
  const numAmount = maybeNumber(amount)

  if (ladder.length < 1) {
    return null
  }

  if (isNil(numAmount)) {
    return ladder[0]
  }

  let resultDifference = Number.MAX_SAFE_INTEGER
  let result = null

  ladder.forEach((rung) => {
    const maybeRungSize = maybeNumber(rung?.Size)

    if (maybeRungSize && maybeRungSize <= numAmount) {
      const diff = numAmount - maybeRungSize

      if (diff < resultDifference) {
        resultDifference = diff
        result = rung
      }
    }
  })

  return result
}

/**
 * makeMonthAgo - return a new `Date` object representing the time one month
 * prior to now.
 */
export const makeMonthAgo = (): Date => {
  return subMonths(new Date(), 1)
}

/**
 * sortSizeBuckets - Return a new copy of the specified `sizeBuckets`, with any empty
 * entries set to the specified `normalSize`, and placed in ascending order.
 */
export const sortSizeBuckets = (
  sizeBuckets: string[],
  normalSize: string,
): string[] => {
  if (!sizeBuckets) {
    return null
  }

  const result = sizeBuckets
    .map((size) => size?.trim() ?? '')
    .map((size) => (0 === size.length ? normalSize : size))

  if (result.length < 2) return result

  result.sort((a, b) => {
    const aNum = maybeNumber(a)
    const bNum = maybeNumber(b)

    if (isNil(aNum)) {
      return isNil(bNum) ? 0 : 1
    } else if (isNil(bNum)) {
      return -1
    } else {
      return aNum - bNum
    }
  })

  return result
}

export const formatClipInterval = (
  units: ClipIntervalUnits,
  amount: string,
) => {
  if (units == 'hours') {
    return `${amount}h`
  } else if (units == 'minutes') {
    return `${amount}m`
  } else if (units === 'seconds') {
    return `${amount}s`
  }
}

export const getCurrencyIconBySymbol = (symbol: string) =>
  CurrencyIconMap[symbol]
