import { useState, useEffect, useRef, useMemo } from 'react'

import { createReqId } from 'lib/app-util'
import { createSubscription } from 'lib/subscription'
import { StreamParameters } from 'types/api/stream-parameters'
import { CancelSubscription } from 'types/cancel-subscription'
import { TalosDataResponse } from 'types/talos-data-response'

import { useResponseProcessor } from './useResponseProcessor'

/**
 * useTalosSubscription - provide the latest `TalosDataResponse` message received for
 * the specified `stream` via the specified `socket`. The expectation is that every
 * message received for a `stream` is a complete snapshot of the entire model. Therefore
 * it is not necessary to watch the sequence number and resubscribe on sequence miss.
 * @param socket
 * @param streams
 * @returns TalosDataResponse
 */
export const useTalosSubscription = <DataType>(
  socket: WebSocket,
  stream: StreamParameters,
): TalosDataResponse<DataType> => {
  const [latestResponse, setLatestResponse] =
    useState<TalosDataResponse<DataType>>()
  const reqId = useRef<number>(createReqId())

  useResponseProcessor(socket, setLatestResponse, reqId)

  const [subscribe, cancel] = useMemo(() => {
    return [
      () => {
        createSubscription(socket, reqId.current, stream)
      },
      () => {
        if (1 !== socket.readyState) return

        const cancelMessage: CancelSubscription = {
          type: 'cancel',
          reqid: reqId.current,
        }
        socket.send(JSON.stringify(cancelMessage))
      },
    ]
  }, [socket, stream, reqId])

  useEffect(() => {
    if (socket && stream) {
      if (1 === socket.readyState) {
        subscribe()
      }

      socket.addEventListener('open', subscribe)

      return () => {
        cancel()
        socket.removeEventListener('open', subscribe)
      }
    }
  }, [socket, stream, subscribe, cancel])

  return latestResponse
}
