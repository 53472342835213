// TBD: replace with REST request to service, given deployment level and user
// launchdarkly.com has a nice solution with all the bells and whistles

export type IsFeatureEnabled = (feature: string) => boolean

const STATIC_FEATURE_FLAGS = {
  'view.nav.trading': true,
  'view.nav.rfq': true,
  'view.nav.balances': false,
  'view.nav.support': false,
  'view.nav.settings': false,
  logging: process.env.NODE_ENV === 'development',
}

export const isFeatureEnabled: IsFeatureEnabled = (featureName) => {
  return STATIC_FEATURE_FLAGS[featureName] ?? false
}

export const ONLY_FOR_TESTING = { STATIC_FEATURE_FLAGS }
